import React, { useEffect, useState } from 'react'
import {
	chakra,
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Text,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	SimpleGrid,
	Spinner,
	Image,
	useBreakpointValue,
} from '@chakra-ui/react'
import { CheckCircleRounded } from '../../atoms/Icon'
import { useCheckoutContext } from '../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../api/checkout/utils'

export const ModalSubscriptionCheckout = ({ isOpen, onClose, onContinue }) => {
	const {
		currency,
		productsArray,
		cart,
		cart: { isFetching, data },
		promotion: { removePromoCode },
		cartCalculations: { subTotal, total },
	} = useCheckoutContext()

	// console.log("data?.subscriptionUpsell.discountAmount",data?.subscriptionUpsell.discountPercent)
	const originalPrice = isFetching ? <Spinner /> : formatToDecimalAmount(subTotal, currency?.symbol)
	const memPrice = isFetching ? (
		<Spinner />
	) : data?.totals?.appliedDiscount > data?.subscriptionUpsell?.discountAmount ? (
		formatToDecimalAmount(total, currency?.symbol)
	) : (
		formatToDecimalAmount(subTotal - data?.subscriptionUpsell?.discountAmount, currency?.symbol)
	)

	const couponIsLess = data?.totals?.appliedDiscount < data?.subscriptionUpsell.discountAmount

	const savings =
		data?.totals?.appliedDiscount > data?.subscriptionUpsell.discountAmount
			? data?.promotion && data?.promotion?.type == 'percent'
				? `${data?.promotion?.value}%`
				: formatToDecimalAmount(data?.promotion?.value, currency.symbol)
			: `${data?.subscriptionUpsell?.discountPercent}%`

	const [qcPrice, setQcPrice] = useState(900)

	useEffect(() => {
		if (productsArray.length > 0) {
			setQcPrice(productsArray.find((el) => el.metadata.slug === 'qualityCheck').pricing.price)
		}
	}, [productsArray])

	const isCentered = useBreakpointValue({ base: false, md: true })
	const confetti = useBreakpointValue({ base: null, md: 'confetti' })

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			scrollBehavior="outside"
			variant={confetti}
			size="xl"
			isCentered={isCentered}>
			<ModalOverlay />
			<ModalContent p="2rem 1rem" m="0">
				{/* <ModalCloseButton /> */}
				<ModalBody>
					<VStack textAlign="center" spacing="0.5rem">
						<Heading size="sm" color="teal.700">
							✨ Exclusive Offer ✨
						</Heading>
						<Heading fontSize={['21px', '26px', '30px']} lineHeight={['21px', '36px']} m="0">
							Become a Member and Save
						</Heading>
						<Text fontSize={['1rem', '1.25rem']} fontWeight="400">
							You’ll <chakra.strong color="black">save {savings}</chakra.strong> on today’s order and get
							a <chakra.strong color="black">1-month free membership</chakra.strong>.
						</Text>
					</VStack>
					<Heading
						textAlign={'center'}
						fontSize={['18px', '20px', '22px']}
						lineHeight={['21px', '28px']}
						mt="1.5rem">
						Member Perks:
					</Heading>
					<SimpleGrid columns={[2, 4]} spacing={2} textAlign="center" mt="1rem" mb="1.25rem">
						<VStack borderRadius=".5rem" backgroundColor="teal.600" p=".5rem .5rem 1.25rem">
							<Image src="/assets/images/pages/checkout/membership-pros-1.png" maxW={'100px'} />
							<Heading fontSize={'16px'} fontWeight="600" color="white" my=".5rem">
								3 VidDays
								<br />
								Per Month
							</Heading>
						</VStack>
						<VStack borderRadius=".5rem" backgroundColor="teal.600" p=".5rem .5rem 1.25rem">
							<Image src="/assets/images/pages/checkout/membership-pros-2.png" maxW={'100px'} />
							<Heading fontSize={'16px'} fontWeight="600" color="white" my=".5rem">
								Priority
								<br />
								Processing
							</Heading>
						</VStack>
						<VStack borderRadius=".5rem" backgroundColor="teal.600" p=".5rem .5rem 1.25rem">
							<Image src="/assets/images/pages/checkout/membership-pros-3.png" maxW={'100px'} />
							<Heading fontSize={'16px'} fontWeight="600" color="white" my=".5rem">
								Unlimited Republishes
							</Heading>
						</VStack>
						<VStack borderRadius=".5rem" backgroundColor="teal.600" p=".5rem .5rem 1.25rem">
							<Image
								src="/assets/images/pages/checkout/membership-pros-4.png"
								maxW="100px"
								borderRadius=".5rem"
							/>
							<Heading fontSize={'16px'} fontWeight="600" color="white" my=".5rem">
								eCards for All Occasions
							</Heading>
						</VStack>
					</SimpleGrid>

					{/* <SimpleGrid columns={2} spacing={2} textAlign="center" mt={['2.25rem', '2.5rem']}>
						<Box borderRadius=".5rem" border="1px solid" borderColor="gray.400" p=".5rem">
							<Text variant="title" mb=".25rem" fontSize="1.25rem">
								Non-Members
							</Text>
							<Text mb=".25rem" fontSize={['.75rem', '1rem']}>
								<chakra.strong color="black">Pay Per</chakra.strong> Video
							</Text>
							<Text mb=".25rem" fontSize={['.75rem', '1rem']}>
								<chakra.strong color="black">Full Price</chakra.strong> Quality Checks
							</Text>
							<Text mb=".25rem" fontSize={['.75rem', '1rem']}>
								<chakra.strong color="black">30%</chakra.strong> Republish Fee
							</Text>
						</Box>
						<Box
							borderRadius=".5rem"
							border="1px solid"
							borderColor="teal.700"
							bg="teal.700"
							p=".5rem"
							position="relative"
							_before={{
								position: 'absolute',
								content: "''",
								display: 'block',
								top: '-33px',
								left: '60%',
								transform: 'translate(-50%, 0)',
								width: '100%',
								height: '32px',
								backgroundImage: 'url(/assets/images/bg/modal-confetti.svg)',
								backgroundRepeat: 'no-repeat',
								backgroundSize: '80%',
							}}>
							<Text color="white" variant="title" fontSize="1.25rem" mb=".25rem">
								Member Perks
							</Text>
							<Text color="white" mb=".25rem" fontSize={['.75rem', '1rem']}>
								<chakra.strong>Free</chakra.strong> VidDays
							</Text>
							<Text color="white" mb=".25rem" fontSize={['.75rem', '1rem']}>
								<chakra.strong>Discounted</chakra.strong> Quality Checks
							</Text>
							<Text color="white" mb=".25rem" fontSize={['.75rem', '1rem']}>
								<chakra.strong>Free</chakra.strong> Republishes
							</Text>
						</Box>
					</SimpleGrid> */}
					<Box m="1rem 0" textAlign="center">
						<Text>
							<chakra.strong color="black">30-day free trial</chakra.strong>
							<br />
							{formatToDecimalAmount(data?.subscriptionUpsell?.monthlyFee || 999, currency.symbol)}
							/month after &nbsp; • &nbsp; Cancel anytime
						</Text>
					</Box>
					<VStack w="full" spacing="1rem" justifyContent="center">
						<Button
							onClick={() => {
								if (couponIsLess) {
									removePromoCode().then(() => {
										onContinue(true)
									})
								} else {
									onContinue(true)
								}
							}}>
							Become a Member
						</Button>
						<Button onClick={() => onContinue()} variant="ghost" padding="1.25em">
							Continue as a Non-Member
							<br />
							and Pay Full Price
						</Button>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
